import numeral from "numeral";

numeral.register("locale", "brasil", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: "mil",
    million: "milhões",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return "º";
  },
  currency: {
    symbol: "R$"
  }
});

numeral.locale("brasil");

export const formata = {
  methods: {
    formatar_valor(val) {
     
      let val2 = val
      if(val != 0){
         val2 = val.replace('.',',')
      }      
    
      let valor = numeral(val2);
     // let valformat = numeral(valor ).value;
      return valor.format(' 0,0.00')
    },
     formatar_val(val) {
       let val2 = val 
      if(val != 0){
        let val3 = val2/100;
         val3 = val3.replace(',','')
      }      
    
      let valor = numeral(val2);
      return valor.format(' 0,0.00')
    },
    formatar_moeda(val) {
      let val2 = val
      if(val > 0){
         val2 = val.replace('.',',')
      }      
    
      let valor = numeral(val2);
     // let valformat = numeral(valor ).value;
      return valor.format('$ 0,0.00')
    },
    upperCase: function(valor) {
      return valor.toUpperCase();
    }
  }
};
