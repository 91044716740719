<template>
  <v-main class="ml-0 mt-2 mr-2">
    <div class="ml-2">
   
      <v-card max-width="100%" elevation="1">
      <v-alert
        style="max-height: 47px;!important"
        color="topo_menu"
        class="text-left"
      >
        <v-breadcrumbs :items="itemsBread">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-alert>     
      
          <v-row class="mx-auto mt-0 ml-0 pa-5">

            <!-- <v-col cols="12" xl="2" lg="4" md="4" sm="6" xs="12">
              <v-autocomplete
                transition="fab-transition"
                :items="listaContas"
                v-model="lancamento.id_conta"
                name="conta"
                label="Escolha uma conta"
                item-value="id_conta"
                item-text="conta"
                filled
                @change="buscaLista()"
              
                hide-details
              ></v-autocomplete>
            </v-col>-->

           <v-col cols="10" xl="2" lg="2" md="2" sm="3" xs="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateInicialFormatted"
                    label="data inicial"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    
                    v-on="on"
                    @input="
                      lancamento.data_final = parseDate(dateFinalFormatted);                      
                    "
                    
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_inicial"
                  no-title
                  @input="
                    menu2 = false;buscaLancamentos()
                  
                  "
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="10" xl="2" lg="2" md="2" sm="3" xs="6">
              <v-menu
                v-model="menuFin"
                :close-on-content-click="false"
                transition="scroll-y-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFinalFormatted"
                    label="data final"
                    persistent-hint
                    prepend-inner-icon="event"
                    filled
                    
                    v-on="on"
                    @input="
                      lancamento.data_final = parseDate(dateFinalFormatted);
                      ;
                    "
                   
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="lancamento.data_final"
                  no-title
                  @input="menuFin = false;buscaLancamentos()"
                  hide-details
                ></v-date-picker>
              </v-menu>
            </v-col>

           
            
           <!--  <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="1">
                  <v-btn
                      
                      color="error"
                      @click="gerarPdf()"
                      outlined
                      small
                    >
                    <v-icon>mdi-pdf-box</v-icon>
                    </v-btn>                    
            </v-col>
            <v-col cols="12" xl="1" lg="1" md="2" sm="6" xs="1">
              <v-btn
                      
                      color="success"
                      @click="gerarExcel()"
                      outlined
                      small
                    >
                    <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
            </v-col>-->
          </v-row>
          
      
    </v-card>
      <v-card class="mt-5">
    
     <v-row no-gutters>
      <v-col style="padding:6px 0px 6px 0px!important" cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
           
    
      <v-data-table
        :headers="headersLanc"
        :items="listaLancamentos"
        :loading="loading_table"
            loading-text="Carregando... Aguarde"
            dense
            :search="search"
            no-data-text="Nenhum resultado encontrado!"
            no-results-text="Nenhum resultado encontrado!"
            class="elevation-3 mt-0"
      >
        <template v-slot:item.actions="{ item }">
              <v-icon
                color="primary"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon color="error" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.vlr_total="{ item }">
              <span>{{ formatar_valor(item.vlr_total) }}</span>
            </template>
            <template v-slot:item.data_lancamento="{ item }">
              <span>{{ formatDate(item.data_lancamento) }}</span>
            </template>
            <template v-slot:item.data_vencimento="{ item }">
              <span>{{ formatDate(item.data_vencimento) }}</span>
            </template>
            <template v-slot:item.data_baixa="{ item }">
              <span>{{ formatDate(item.data_baixa) }}</span>
            </template>
            <!--<template v-slot:item.pai="{ item }">
              <div v-if="item.pai">
                <v-btn
                  icon
                  color="error"
                  @click="openFilhos(item.pai)"
                  fab
                  x-small
                >
                  <v-icon>mdi-link-variant</v-icon>
                </v-btn>
              </div>
            </template>-->
          </v-data-table>
   
    
     </v-col>
    </v-row>
    </v-card>
    </div>
     <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class=""
          >Tem certeza que deseja excluir este Registro?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete=false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
         <a href="#" target="_blank" id="linkDownload" download></a>
  </v-main>
  
  </v-container>
</template>

<script>
var moment = require("moment");

import { formata } from "../plugins/format";
//import DialogItensLancamento from "./DialogItensLancamento.vue";
const auth = {
  headers: {
    Authorization: "bearer " + window.sessionStorage.getItem("token"),
  },
};
export default {
  name: "Extrato",
  mixins: [formata],
    props: {
    source: String,
  },
  mounted() {
    
    this.buscaLancamentos();
    
  },
  data: (vm) => ({
    valorSel: null,
    selFormaPagto: "",
    tipo_data: "vencimento",
    dialogItens: false,
    id_pai: "",
    lancamento: {
      descricao: "",
      id_fin_grupo_departamento: "",
      vlr_valor: "",
      data_pagamento: moment().format("YYYY-MM-DD"),
      data_inicial: moment().format("YYYY-MM-01"),
      data_final: moment().format("YYYY-MM-DD"),

      id_conta: "",
    },
      itemsBread: [
      {
        text: "Financeiro",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Consulta Lançamentos",
        disabled: false,
        href: "/dashboard/consulta_lancamentos",
      },
    ],
    selDel: "",
    dialogDelete: false,
    barSaldos: true,
    barTabela: false,
    isLoading: false,
    // date_ini: moment().format("YYYY-MM-01"),
    //date_vencimento: new Date().toISOString().substr(0, 10),
    //date_pagamento: new Date().toISOString().substr(0, 10),
    datePagamentoFormatted: moment().format("DD/MM/YYYY"),
    //data_inicial: moment().format("YYYY-MM-01"),
    //data_final: new Date().toISOString().substr(0, 10),
    dateInicialFormatted: moment().format("01/MM/YYYY"),
    dateFinalFormatted: moment().format("DD/MM/YYYY"),
    menu1: false,
    menu2: false,
    menu3: false,
    menuFin: false,
    loading_table: false,
     search: "",
    loading_filtrar: false,
    loading_baixar: false,
    //grupos: [],

    historicos: [],
    fornecedores: [],
    listaTipoDoc: [],
    listaLancamentos:[],
    
    saldoAnterior:0,
    headersLanc: [
      {
        text: "Nº",
        sortable: true,
        align: "left",
        value: "id_lancamento",
        
      },
      {
        text: "Descrição",
        value: "descricao",
      },
      {
        text: "Identif.",
        value: "identif",
        align: "left",
        sortable:false,
      },
      {
        text: "Data",
        value: "data_lancamento",
        align: "right",
      },

      {
        text: "Valor",
        value: "vlr_total",
        align: "right",
      },

      {
        text: "Vencimento",
        value: "data_vencimento",
        align: "right",
      },
      
      {
        text: "Tipo",
        value: "tipo",
        align: "left",
        sortable:false,
      },
      {
        text: "Documento",
        value: "documento",
        align: "left",
        sortable:false,
      },
      {
        text: "Data Baixa",
        value: "data_baixa",
        align: "left",
      },
      {
        text: "Grupo Financeiro",
        value: "grupo_financeiro",
        align: "left",
      },
      
      {
        text: "Conta",       
        value: "conta",
        align: "left",
      },
      //{ text: "Ações", value: "actions", sortable: false },
    ],
    listaContas: [],
    v_autoselect: null,
   
  }),
  computed: {
    dateInFormatted() {
      return this.formatDate(this.lancamento.data_lancamento);
    },
    /*dateVencimentoFormatted() {
      return this.formatDate(this.lancamento.data_vencimento);
    },*/
  },
 

  watch: {
    v_autoselect(val) {
      console.log("auto" + val);
    },
    "lancamento.data_inicial"(val) {
      this.dateInicialFormatted = this.formatDate(this.lancamento.data_inicial);
    },
    "lancamento.data_final"(val) {
      this.dateFinalFormatted = this.formatDate(this.lancamento.data_final);
    },
    "lancamento.data_pagamento"(val) {
      this.datePagamentoFormatted = this.formatDate(
        this.lancamento.data_pagamento
      );
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogItens(val) {
      if (val == true) {
        this.buscaDadosItens();
      }
    },
  },
  methods: {
    gerarExcel(){
      
      this.buscaExtratoExcel()
    },
    gerarPdf(){
       this.buscaExtratoPdf();
    },
   
    buscaExtratoExcel() {
      this.loading = true;
      axios.post(
          `/fin/conta/extrato/excel`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
            data_inicial: this.lancamento.data_inicial,
            data_final: this.lancamento.data_final,
            id_conta: this.lancamento.id_conta,
          }
       ).then(response => {
        if (response.data.error) {
          this.textSnack = response.data.error;
          this.snackbar = true;
        } else {
          document.getElementById("linkDownload").href = response.data.url;
          document.getElementById("linkDownload").click();
        }
        this.loading = false;
      });
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
    zeraCampos() {
      //   alert("campos");
      this.lancamento.id_conta = "";
      this.valorSel = "";
    },
    mudaTab(tab) {
      //alert("conta" + conta);
      //this.conta;
      //this.lancamento.id_conta = e;
      //alert(e);
      //  this.$refs.lancamento.selConta(e);
      this.$emit("mudaTab", tab);
    },
    atrribuirConta(val) {
      this.valorSel = val;
      for (let [key, value] of Object.entries(this.listaContasPagar)) {
        //console.log(`${key}: ${value}`);
        this.listaContasPagar[key].id_conta = val;
        //this.listaContasPagar[key].data_pagamento = val;
      }
    },
    addContaItem(id_lancamento, index, e) {
      // this.lancSelPagar.push({ id_lanc: id_lancamento, conta: e });
      //alert(id_lancamento);
      //  alert(e.target.value);
      // console.log("indkkk" + index);
      // console.log(this.lancSelPagar);
      this.listaContasPagar[index].id_conta = e;
    },
    buscaDadosItens() {
      setTimeout(
        () => this.$refs.dialogIt.buscaLancamentosFilhos(this.id_pai),
        300
      );
    },
    openFilhos(id_pai) {
      this.id_pai = id_pai;
      setTimeout(() => (this.dialogItens = true), 200);
    },
    closePagar() {
      this.dialogPagar = false;
    },
    showPagar(item) {
      if (
        this.lancamento.data_pagamento == "" ||
        this.lancamento.data_pagamento == null
      ) {
        alert("informe a data de pagamento");
        return false;
      }
      this.selPagar = item;
      console.log(this.selPagar);
      this.dialogPagar = true;
    },
   
    deleteItem(item) {
      console.log(item);
      console.log(item.id_lancamento);
      //this.editedIndex = this.lancamento.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.selDel = item.id_lancamento;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.listaLancamentos.splice(this.editedIndex, 1);
      this.closeDelete();
      axios
        .post(
          `/fin/lancamento/remove`,
          { id: this.selDel, cnpj: window.sessionStorage.getItem("cnpj") },
          auth
        )
        .then((response) => {
          this.selDel = "";
          this.buscaContasPagar();
          console.log(response);
          //this.grupos = response.data;
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    editItem(item) {
      this.lancamento = item;
    },
    formata_moeda(val) {
      let valor = this.formatar_moeda(val);
      return valor;
    },
    addDescricao(val) {
      this.lancamento.descricao = val;
    },
    selecionarConta(id) {
      this.lancamento.id_conta = id;
      //  sessionStorage.setItem("selconta", id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getStatus(status) {
      if (status === "Cancelada") {
        return "cancelada";
      }
    },

    selConta(e) {
      console.log(e);
      //this.lancamento.id_conta = e;
      // alert(e);
      //this.$refs.lancamento.selConta(e);
    },
    buscaSaldos() {
      this.$refs.saldos.buscaSaldos();
    },
    filtroBuscar() {
      setTimeout(() => this.buscaContasPagar(), 500);
    },
     buscaLancamentos() {
      this.loading_table = true;
      axios
        .post(
          `/fin/lancamento/consulta`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),          
            data_inicial: this.lancamento.data_inicial,
            data_final: this.lancamento.data_final,
          },
          auth
        )
        .then((response) => {
          this.listaLancamentos = response.data;

          this.barTabela = false;
          this.loading_table = false;
          this.conta = window.sessionStorage.getItem("conta");
        });
    },
    filtrarData() {
      this.filtroBuscar();
    },
    buscaContas(val) {
      this.listaContas = [];
      axios
        .post(
          `/fin/conta/lista`,
          {
            cnpj: window.sessionStorage.getItem("cnpj"),
          },
          auth
        )
        .then((response) => {
          this.listaContas = response.data;
        });
    },
  },
  /*removeConta() {
    alert("remove");
  },*/
};
</script>
<style scoped>
.ativa {
  border: 2px solid #000 !important;
}
</style>
